import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/SEO';
import SectionIntro from '../../../components/SectionIntro';
import {getImage} from 'gatsby-plugin-image';
import {Link, useStaticQuery, graphql} from 'gatsby';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const dataRelease = [
  {
    source: 'Press Release',
    title: 'Trustle Appoints Gant Redmon as Chief Executive Officer to Drive Next Level of Growth',
    date: 'Nov. 12th, 2024',
    tag: 'press',
    link: '/company/releases/trustle-appoints-gant-redmon-as-chief-executive-officer-to-drive-next-level-of-growth',
  },
  {
    source: 'Tech Careers & Money Talk',
    title: `Unlocking CEO Skills: Emiliano’s Journey from Developer to CEO`,
    date: 'Dec. 28th, 2023',
    tag: 'news',
    link: 'https://www.techcareersandmoneytalk.com/CEO-skills/',
  },
  {
    source: 'Security Architecture',
    title: `Trustle on Cyber inspiration Podcast`,
    date: 'Dec. 14th, 2023',
    tag: 'news',
    link: 'https://www.security-architecture.org/episode/cie055-trustle',
  },
  {
    source: 'Intellyx',
    title: `Trustle receives Winter 2023 Intellyx Digital Innovator Award`,
    date: 'Dec. 5th, 2023',
    tag: 'news',
    link: 'https://intellyx.com/2023/12/05/winter-2023-intellyx-digital-innovator-award-winners-announced/',
  },
  {
    source: 'Last Watchdog',
    title: `Trustle CEO Talks ITDR on Bryon Acohido’s "The Last Watchdog" Podcast`,
    date: 'Dec. 4th, 2023',
    tag: 'news',
    link: 'https://www.lastwatchdog.com/black-hat-fireside-chat-easy-come-easy-go-access-strengthens-identity-threat-detection-response/',
  },
  {
    source: 'Intellyx',
    title: 'Trustle: Practical Least-Privilege Entitlement Management',
    date: 'Aug. 12th, 2023',
    tag: 'news',
    link: 'https://intellyx.com/2023/08/12/trustle-practical-least-privilege-entitlement-management/',
  },
  {
    source: 'Press Release',
    title: 'Trustle Appoints Marc Boroditsky to Board of Directors',
    date: 'Jun. 14th, 2023',
    tag: 'press',
    link: '/company/releases/trustle-appoints-marc-boroditsky-to-board-of-directors',
  },
  {
    source: 'Press Release',
    title: 'Trustle Raises $6M Seed Round -Press Release',
    date: 'Apr. 4th, 2023',
    tag: 'press',
    link: '/company/releases/trustle-raises-6m-seed-funding-round',
  },
  {
    source: 'Security Week',
    title: 'Trustle Raises $6M Seed Funding for Access Management Tech',
    date: 'Apr. 4th, 2023',
    tag: 'news',
    link: 'https://www.securityweek.com/trustle-6-million-seed-funding-round/',
  },
];

const Badge = ({type = 'press'}) => {
  const badgeColor = type === 'news' ? 'bg-[#008A45]' : 'bg-blue-dark';
  const label = type === 'news' ? 'News Article' : 'Press Release';
  return <span className={`text-xs font-bold ${badgeColor} text-white rounded-full py-1 px-2`}>{label}</span>;
};

const CardRelease = ({title, source, tag, link, date}) => {
  const contentCard = (
    <div className="bg-[#F8F9FC] group hover:bg-white cursor-pointer hover:drop-shadow-xl transition duration-300 ease-in-out rounded-3xl px-4 md:px-8 py-6 w-full lg:w-[416px] lg:h-[416px] mx-auto ">
      <div className="flex-col text-lg md:text-2xl text-blue">
        <div className="flex items-center justify-between pb-8">
          <div className="">{source}</div>
          <div className="transition duration-300 ease-in-out opacity-0 transform group-hover:translate-y-0 translate-y-[-12px] motion-safe:group-hover:scale-100 scale-150 group-hover:opacity-100 group-hover:translate-x-0 translate-x-[12px] group-active:translate-x-[12px] group-active:translate-y-[-12px] group-active:duration-100 ">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.94508 18.8201L15.5113 6.25384L15.5113 18.8313L18.6951 18.8201L18.6951 0.820092L0.695082 0.820093L0.695081 3.99259L13.2613 4.00384L0.695081 16.5701L2.94508 18.8201Z"
                fill="#0091FF"
              />
            </svg>
          </div>
        </div>

        <div className="h-40 mb-4 text-2xl font-bold md:text-3xl">{title}</div>
        <div className="pb-4">{date}</div>
        <Badge type={tag} />
      </div>
    </div>
  );
  return (
    <>
      {tag === 'news' ? (
        <a href={link} target="_blank" rel="noreferrer">
          {contentCard}
        </a>
      ) : (
        <Link to={link} partiallyActive={true} partialMatch={true}>
          {contentCard}
        </Link>
      )}
    </>
  );
};

const Releases = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {backgroundImage} = dataImages;

  const image02 = getImage(backgroundImage);
  const bgImage02 = convertToBgImage(image02);

  return (
    <Layout
      seo={
        <Seo
          title="Newsroom | Trustle"
          description="Stay up-to-date with everything that is happening with the company. Breaking News and Press releases"
        />
      }
    >
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage02}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="pb-16 bg-gradient-to-b xl:pb-64">
          <div className="flex flex-col py-16 lg:pb-32 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro
              isPageHeader
              hero="Company"
              title="Newsroom"
              description={`Stay up-to-date with everything that is happening with the company.</br>Breaking News and Press releases.`}
            />

            <div className="max-w-[1310px] grid grid-cols-1 gap-8 mx-auto md:grid-cols-2 xl:grid-cols-3">
              {dataRelease.map((release, index) => (
                <CardRelease
                  title={release.title}
                  source={release.source}
                  tag={release.tag}
                  link={release.link}
                  date={release.date}
                  key={`${index}-${release.title}`}
                />
              ))}
            </div>
          </div>
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default Releases;
